import { TOKEN_KEY } from "@/constants";
import { getProfile } from "@/services/user";
import React, { useState } from "react";
import { useQuery } from "react-query";

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = React.createContext<{
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  isChecking: boolean;
  setIsChecking: React.Dispatch<React.SetStateAction<boolean>>;
  logout: () => Promise<any>;
  user: any;
  refetch: () => any;
}>({} as any);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(localStorage.getItem(TOKEN_KEY))
  );
  const [isChecking, setIsChecking] = useState(true);

  const {
    data: user = {
      dealer: {},
    },
    refetch,
  } = useQuery(["user-details"], () => getProfile(), {
    retry: 0,
    refetchOnWindowFocus: false,
    enabled: isLoggedIn,
  });

  const logout = async () => {
    localStorage.removeItem(TOKEN_KEY);
  };

  return (
    <AuthContext.Provider
      value={{
        logout,
        user,
        isLoggedIn,
        setIsLoggedIn,
        isChecking,
        setIsChecking,
        refetch,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
