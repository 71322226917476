import { BidIcon, NotifyIcon, SignupIcon } from "@/assets/images";
import VehicleProcess, {
  VehicleProcessDataProps,
} from "@/components/VehicleProcess";

const processData: VehicleProcessDataProps[] = [
  {
    id: "1",
    title: "Sign Up",
    icon: SignupIcon,
    description: [
      "Quick and Easy 2 Minute Process",
      "Mobile and Destop Access",
      "Secure Data Encryption",
    ],
  },
  {
    id: "2",
    title: "Millions of Cars in Inventory",
    icon: NotifyIcon,
    description: [
      "Search for Available Inventory",
      "Review Available Inventory",
      "Make an Offer to Buy",
    ],
  },
  {
    id: "3",
    title: "Offer,Buy, and Sell",
    icon: BidIcon,
    description: [
      "Receive Your Offer Alerts",
      "Enter the Live Chat",
      "Negotiate and Buy and Sell",
    ],
  },
];

const NotifyProcess = () => {
  return <VehicleProcess processData={processData} />;
};
export default NotifyProcess;
