import CompanyName from "@/components/CompanyName";
import SignupButton from "@/components/SignupButton";
import {
  MessageImage,
  OfferAccepted,
  ChatImage,
  StartingWholesale,
} from "@/assets/images";

import styles from "./styles.module.scss";

const BuyAndSellSection = () => {
  return (
    <>
      <div className={styles.buywiserSeaction}>
        <h1 className={styles.titleHeading}>
          Buy Wiser, Sell Wiser, <CompanyName />
        </h1>
        <div className={styles.buywiserimaSection}>
          {[MessageImage, OfferAccepted, ChatImage, StartingWholesale].map(
            (icon) => (
              <img
                src={icon}
                key={icon}
                alt="MessageImage"
                className={styles.buywiserImages}
              />
            )
          )}
        </div>
      </div>
      <SignupButton />
    </>
  );
};
export default BuyAndSellSection;
