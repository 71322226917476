import React from "react";
import {
  Col,
  Row,
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardLink,
} from "reactstrap";
import styles from "./styles.module.scss";
import { Profile, Heart,Star} from "@/assets/images";

export interface CardDataProps {
  id: string;
  name?: string;
  icon?: string;
  details?: string[];
}
interface DealersDetailsProps {
    dealerData: CardDataProps[];
}

const DealersData: React.FC<DealersDetailsProps> = ({ dealerData }) => {
  return (
    <>
      <Row className="mt-3">
        {dealerData.map((step) => (
          <Col key={step.id}>
            <Card className={styles.cardSection}>
              <CardBody className={styles.cardBody}>
                <div className={styles.profileSection}>
                  <img src={Profile} alt="profile"></img>
                  <CardTitle tag="h5" className={styles.cardTitle}>
                    {step.name}
                  </CardTitle>
                  <img src={Heart}  alt="heartLogo"></img>
                </div>
                <div className={styles.starSection}>
                <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                </div>
                <CardText className={styles.cardText}>
                  Some quick example text to build on the card title and make up
                  the bulk of the cards content.
                </CardText>
              </CardBody>
              <CardLink className={styles.cardLink} href="#">
                Read More
              </CardLink>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};
export default DealersData;
