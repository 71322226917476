import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { Loader } from "react-feather";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Container } from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.scss";
import AppRoute from "./routes";

dayjs.extend(utc);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {},
    mutations: {},
  },
});

const App: React.FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getConfig = async () => {
      try {
        window.ENV = (await axios.get("/config.json")).data;
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    };

    getConfig();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>Config not provided</p>;
  }
  return (
    <QueryClientProvider client={queryClient}>
      <Container fluid className="app-container">
        <AppRoute />
      </Container>
      <Toaster />
      {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools position="bottom-right" />
      )}
    </QueryClientProvider>
  );
};

export default App;
