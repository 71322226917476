import CompanyName from "@/components/CompanyName";
import SignupButton from "@/components/SignupButton";
import { Col, Row } from "reactstrap";
import styles from "./styles.module.scss";

const DealerPartner = () => {
  return (
    <>
      <div className={styles.wholesaleContainer}>
        <Row xs="2">
          <Col xs={12} md={12} lg={6} className={styles.vehicleBodySeaction}>
            <div>
              <div className={styles.sellingList}>
                <ul className={styles.bodyList}>
                  <li>A Dealer to Dealer Platform</li>
                  <li>Free Until You Buy or Sell</li>
                  <li>$250 Flat Buy/Sell Fee</li>
                  <li>Never Go to An Auction Again</li>
                  <li>Move Your Aging Inventory</li>
                  <li>Buy and Sell on Mobile in Minutes</li>
                  <li>SELL More Cars! Increase Revenue</li>
                  <li>Never Lose a Customer On Your Lot Again</li>
                  <li>Seamless Shipping Solution</li>
                  <li>100% Secure Online Transactions</li>
                  <li>Virtual Hand Sale</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={6} className={styles.vehicleImageSeaction}>
            <div className={styles.vehicleTextSeaction}>
              <CompanyName className={styles.companyName} />
              <h3>Partner with Dealers</h3>
            </div>
            <SignupButton />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default DealerPartner;
