import { Banner } from "@/assets/images";
import SignupButton from "@/components/SignupButton";
import styles from "./styles.module.scss";

interface WelcomeBannerProps {
  name: string;
}
const BannerSection: React.FC<WelcomeBannerProps> = ({ name })  => {
  return (
    <div className={styles.backgroundArea}>
      <img src={Banner} alt="Banner" />
      <div className={styles.imageText}>
        <h2 className={styles.imageHeading}>The Future Of</h2>
        <h1 className={styles.subHeading}>Buying and Selling</h1>
        <SignupButton />
      </div>
      <div className={styles.bannerFooter}>Welcome {name}...</div>
    </div>
  );
};
export default BannerSection;
