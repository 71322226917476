import RestClient from "@/utils/RestClient";
import get from "lodash/get";
import { getDealer } from "./dealer";

export const getUser = async (id: string) => await RestClient(`user/${id}`);

export const getProfile = async () => await RestClient("user/profile");

export const getUserByEmail = (email: string) =>
  RestClient("/user/by-email", "POST", {
    email,
  });

export const checkUserAccess = async (email: string) => {
  const { user: userData } = await getUserByEmail(email);
  const dealerId = userData._source.payload.dealer.id;
  const dealer = await getDealer(dealerId);

  const access = get(dealer, "_source.payload.access", []);
  if (!access.includes("dealersbid")) {
    throw new Error(
      "You are not authorized to login to the dealers bid system."
    );
  }

  return userData;
};
export const sendOTP = (values: { email: string; mode: ("sms" | "email")[] }) =>
  RestClient(`/user/otp`, "POST", values);
