import { WholesalePortalImage, IconPlay, BidProcess } from "@/assets/images";
import CompanyName from "@/components/CompanyName";
import SignupButton from "@/components/SignupButton";
import { Col, Row } from "reactstrap";
import styles from "./styles.module.scss";

const WelcomeSection = () => {
  return (
    <>
      <div className={styles.textTitle}>
        <h1 className={styles.titleHeading}>This Bid's For You </h1>
        <SignupButton />
        <div className={styles.bidSection}>
          <Row>
            <Col lg={6}>
              <div className={styles.bidimgSection}>
                <img src={WholesalePortalImage} alt="WholesalePortalImage" className={styles.bidImage} />
                <img src={IconPlay} alt="baneer" className={styles.playIcon} />
              </div>
            </Col>
            <Col lg={6}>
              <div className={styles.bidtextSection}>
                <h3 className={styles.mainheading}>See how the</h3>
                <h2 className={styles.companyName}><CompanyName /></h2>
                <h4 className={styles.subHeading}>Wholesale Portal</h4>
                <h5 className={styles.subHeadings}>Work for You</h5>
              </div>
              <div className={styles.bidiconRight}>
                <img
                  src={BidProcess}
                  alt="bidProcess"
                  className={styles.bidIcon}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
     
    </>
  );
};
export default WelcomeSection;
