import { AppRoutes } from "@/constants";
import { useDealer } from "@/hooks/dealer";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BannerSection from "./BannerSection";
import NotifyProcess from "./NotifyProcess";
import PrivateVehicle from "./DealerSignup";
import SellingInventory from "./BuyAndSellSection";
import styles from "./styles.module.scss";
import DealerPartner from "./DealerPartener";
import WelcomeSection from "./WelcomeSection";

const OnboardingLandingPage = () => {
  const [params] = useSearchParams();
  const dealerId = params.get("dealer");
  const navigate = useNavigate();

  const { isSuccess, data } = useDealer(dealerId as string);

  useEffect(() => {
    if (!dealerId) {
      navigate(AppRoutes.HOME);
    }
  }, [dealerId, navigate]);

  return (
    <div className={styles.mainContainer}>
      {isSuccess && <BannerSection name={data._source.payload.dealer_name} />}
      <WelcomeSection />
      <NotifyProcess />
      <DealerPartner />
      <SellingInventory />
      <PrivateVehicle />
    </div>
  );
};
export default OnboardingLandingPage;
