import DealersData, { CardDataProps } from "@/components/DealersCard";

import styles from "./styles.module.scss";

const dealerData: CardDataProps[] = [
  {
    id: "1",
    name: "Samual F.",
  },
  {
    id: "2",
    name: "Ray W.",
  },
  {
    id: "3",
    name: "Victor T.",
  },
  {
    id: "4",
    name: "Blake P.",
  },
  {
    id: "5",
    name: "Dwight B.",
  },
];

const PrivateVehicle = () => {
  return (
    <>
    <h1 className={styles.titleHeading}>Sign Up Now</h1>
    <div className={styles.dealersSection}>
      <h1 className={styles.headingText}>What Dealers Are Saying</h1>
      <DealersData dealerData={dealerData} />
      <h1 className={styles.headingText}>The King of Deals</h1>
    </div>
    
  </>
  );
};
export default PrivateVehicle;
